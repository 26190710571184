import {inject, injectable} from "inversify";
import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {Bindings} from "data/constants/bindings";
import type {IJSONProvider} from "data/providers/json/json.provider";

export interface IEvent {
	name: string;
	date: string;
	logo: string;
	grayscaleLogo: string;
	newsLink: string;
	raceLink: string;
	race: number | null;
}

export interface IEventsStore {
	get events(): IEvent[];

	fetchEvents(): Promise<void>;

	getEventByName(name: string): IEvent | undefined;

	get isScheduleOpen(): boolean;

	set isScheduleOpen(value: boolean);
}

@injectable()
export class EventsStore implements IEventsStore {
	@observable private _events: IEvent[] = [];
	@observable private _isScheduleOpen: boolean = false;

	constructor(@inject(Bindings.JSONProvider) private _jsonProvider: IJSONProvider) {
		makeAutoObservable(this);
	}

	get isScheduleOpen(): boolean {
		return this._isScheduleOpen;
	}

	set isScheduleOpen(value: boolean) {
		this._isScheduleOpen = value;
	}

	get events(): IEvent[] {
		return this._events;
	}

	@action
	public async fetchEvents(): Promise<void> {
		try {
			const {data} = await this._jsonProvider.events();

			if (!data || !Array.isArray(data)) {
				throw new Error("Error while loading events");
			}

			runInAction(() => {
				this._events = data;
			});
			return Promise.resolve();
		} catch (e) {
			return Promise.reject(e);
		}
	}

	public getEventByName(name: string): IEvent | undefined {
		return this._events.find((event) => event.name.toLowerCase() === name.toLowerCase());
	}
}
