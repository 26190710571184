import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {makeAutoObservable, observable} from "mobx";
import {ModalType, TeamPosition} from "data/enums";
import {Bindings} from "data/constants/bindings";
import type {IHorse} from "data/types/race";
import type {ITeam, ITeamPositionCommonProps} from "data/types/team";
import type {ITeamStore} from "data/stores/team/team.store";
import type {IHorsesStore} from "data/stores/horses/horses.store";
import type {IPlayerPoolStore} from "data/stores/player_pool/player_pool.store";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {IRacesStore} from "data/stores/races/races.store";

export interface ITeamHorseSelectedController extends ViewController<ITeamPositionCommonProps> {
	removeHorseFromPosition: () => void;
	openHorseModal: () => void;

	get i18n(): ILocalizationStore;

	get horse(): IHorse | undefined;

	get isRemoveEnabled(): boolean;

	get isCaptain(): boolean;

	get isDonkeyInsurance(): boolean;

	get isLockout(): boolean;

	get isPerfect(): boolean;

	get points(): number | undefined;
}

@injectable()
export class TeamHorseSelectedController implements ITeamHorseSelectedController {
	@observable private _position: TeamPosition | undefined;

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.RacesStore) private _racesStore: IRacesStore,
		@inject(Bindings.PlayerPoolStore) private _playerPoolStore: IPlayerPoolStore,
		@inject(Bindings.HorsesStore) private _horsesStore: IHorsesStore,
		@inject(Bindings.TeamStore) private _teamStore: ITeamStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}
	get points(): number | undefined {
		if (!this._position) {
			return;
		}

		if (!this.horse?.finishedPosition && this.isComplete) {
			return 0;
		}

		if (!this.horse?.finishedPosition) {
			return;
		}

		return Object.values(this.team.scoreDetails || {}).reduce((acc, value) => {
			if (value?.horse === this.horseId) {
				return acc + value.pts;
			}
			return acc;
		}, 0);
	}
	private get isComplete(): boolean {
		return this._racesStore.isRaceComplete;
	}

	get isDonkeyInsurance(): boolean {
		if (!this.horseId || !this._teamStore.donkeyInsurance) {
			return false;
		}
		const team = this._teamStore.team as ITeam;
		return team.donkeyInsurance === this.horseId;
	}

	get isCaptain(): boolean {
		if (!this.horseId) {
			return false;
		}
		return this._teamStore.getIsHorseCaptain(this.horseId);
	}

	get horse(): IHorse | undefined {
		if (!this.horseId) {
			return;
		}
		return this._horsesStore.getHorseById(this.horseId);
	}

	get isRemoveEnabled(): boolean {
		return !this._racesStore.isRaceLocked;
	}

	get isLockout(): boolean {
		return this._racesStore.isRaceLocked;
	}

	get isPerfect(): boolean {
		if (!this._position || !this.horseId) {
			return false;
		}
		return this._teamStore.isPerfectTeam;
		// const positionScore = getScoreDetailsPosition(this._position);
		// return this.horseId === this.team.scoreDetails?.[positionScore]?.horse;
	}

	private get team() {
		return this._teamStore.team as ITeam;
	}

	private get horseId() {
		if (!this._position) {
			return;
		}
		return this._teamStore.team?.[this._position] || undefined;
	}

	public removeHorseFromPosition = () => {
		if (!this._position) {
			return;
		}
		this._teamStore.removeHorseByPosition(this._position);
		this._playerPoolStore.updateSelectedPosition(this._teamStore.team);
	};

	public openHorseModal = () => {
		if (!this.horse) {
			return;
		}
		this._modalsStore.showModal(ModalType.HORSE_INFO, {horseId: this.horse.id});
	};

	dispose(): void {
		return;
	}

	init(param: ITeamPositionCommonProps): void {
		this._position = param.position;
	}

	onChange(param: ITeamPositionCommonProps): void {
		this._position = param.position;
	}
}
