import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {makeAutoObservable, observable, runInAction} from "mobx";
import {Bindings} from "data/constants/bindings";
import type {IGeoBlockStore} from "data/stores/geo_block/geo_block.store";
import {RequestState} from "data/enums";

export interface IAdvertController extends ViewController {
	get isAllowed(): boolean;
	get isLoading(): boolean;
}

@injectable()
export class AdvertController implements IAdvertController {
	@observable _requestState = RequestState.IDLE;
	constructor(@inject(Bindings.GeoBlockStore) private _geoBlockStore: IGeoBlockStore) {
		makeAutoObservable(this);
	}

	get isAllowed(): boolean {
		return this._geoBlockStore.isAdAllowed;
	}

	get isLoading(): boolean {
		return this._requestState !== RequestState.SUCCESS;
	}

	init(param: void) {
		this._requestState = RequestState.PENDING;
		this._geoBlockStore
			.checkAd()
			.then(() => {
				runInAction(() => {
					this._requestState = RequestState.SUCCESS;
				});
			})
			.catch(() => {
				runInAction(() => {
					this._requestState = RequestState.ERROR;
				});
			});
	}
}
