import {makeAutoObservable, observable, runInAction, reaction} from "mobx";
import {ViewController} from "data/types/structure";
import {injectable, inject} from "inversify";
import {RequestState} from "data/enums";
import {Bindings} from "data/constants/bindings";
import type {IUserStore} from "data/stores/user/user.store";
import {IUserPersonalizedModal} from "data/providers/api/user.api.provider";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {ITeamStore} from "data/stores/team/team.store";
import {SecretGateController} from "views/controllers/secret_gate/secret_gate.controller";
import {ModalHelper} from "data/utils/modal_helper";

export interface IModalPersonalizedController extends ViewController {
	i18n: ILocalizationStore;

	get personalizedModal(): IUserPersonalizedModal | undefined;

	get isLoading(): boolean;

	get isOpen(): boolean;

	closeModal: () => void;
}

@injectable()
export class ModalPersonalizedController implements IModalPersonalizedController {
	@observable _requestState: RequestState = RequestState.IDLE;
	@observable _isClosed: boolean = false;
	private _disposers: Array<ReturnType<typeof reaction>> = [];
	constructor(
		@inject(Bindings.LocalizationStore) public i18n: ILocalizationStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.TeamStore) private _teamStore: ITeamStore
	) {
		makeAutoObservable(this);
	}

	get isOpen(): boolean {
		if (this._isClosed) {
			return false;
		}
		return !this.isLoading && !!this.personalizedModal;
	}

	get isLoading() {
		return this._requestState !== RequestState.SUCCESS;
	}

	get personalizedModal() {
		return this._userStore.personalizedModal;
	}

	init(param: void) {
		this._disposers = [
			reaction(
				() => {
					return {
						teamCondition:
							!this._teamStore.hasSavedTeam && this._teamStore.isTeamChecked,
						authCondition:
							!this._userStore.isAuthorized && this._userStore.isSessionChecked,
						secretCondition: SecretGateController.IS_SECRET_PASSED,
						viewedCondition: !!ModalHelper.get(),
					};
				},
				({teamCondition, authCondition, secretCondition, viewedCondition}) => {
					if (!secretCondition || viewedCondition) {
						return;
					}

					if (teamCondition || authCondition) {
						this._requestState = RequestState.PENDING;
						this._isClosed = false;
						this._userStore
							.fetchPersonalModal()
							.then(() => {
								runInAction(() => {
									this._requestState = RequestState.SUCCESS;
								});
							})
							.catch(() => {
								runInAction(() => {
									this._requestState = RequestState.ERROR;
								});
							});
					}
				},
				{
					fireImmediately: true,
				}
			),
		];
	}

	dispose() {
		this._disposers.forEach((dispose) => dispose());
	}

	closeModal = () => {
		this._isClosed = true;
		ModalHelper.set(
			JSON.stringify({
				content: this.personalizedModal,
				at: Date.now(),
			})
		);
	};
}
