import React, {lazy, Fragment} from "react";
import {Route, Routes, Outlet} from "react-router-dom";

import {retryFailLoad} from "data/utils";
import {NotAuthOnlyRoute, PrivateRoute} from "views/components/route";
import {Header} from "views/components/header/header.component";
import {Footer} from "views/components/footer/footer.component";

const LandingPage = lazy(retryFailLoad(() => import("views/pages/landing/landing.page")));
const ForgotPassword = lazy(
	retryFailLoad(() => import("views/pages/forgot_password/forgot_password.page"))
);
const ResetPassword = lazy(
	retryFailLoad(() => import("views/pages/reset_password/reset_password.page"))
);
const Team = lazy(retryFailLoad(() => import("views/pages/team/team.page")));
const RivalTeam = lazy(retryFailLoad(() => import("views/pages/rival_team/rival_team.page")));
const Leaderboard = lazy(retryFailLoad(() => import("views/pages/leaderboard/leaderboard.page")));
const MyAccount = lazy(retryFailLoad(() => import("views/pages/my_account/my_account.page")));
const Help = lazy(retryFailLoad(() => import("views/pages/help/help.page")));
const NotFound = lazy(retryFailLoad(() => import("views/pages/not_found/not_found.page")));
const ComponentsReview = lazy(
	retryFailLoad(() => import("views/pages/components_review/components_review.page"))
);

const MyLeagues = lazy(retryFailLoad(() => import("views/pages/my_leagues/my_leagues.page")));
const JoinLeagues = lazy(retryFailLoad(() => import("views/pages/join_leagues/join_leagues.page")));
const CreateLeague = lazy(
	retryFailLoad(() => import("views/pages/create_league/create_league.page"))
);
const League = lazy(retryFailLoad(() => import("views/pages/league/league.page")));
const LeagueTable = lazy(retryFailLoad(() => import("views/pages/league_table/league_table.page")));
const LeagueSettings = lazy(
	retryFailLoad(() => import("views/pages/league_settings/league_settings.page"))
);
const LeagueInvite = lazy(
	retryFailLoad(() => import("views/pages/league_invite/league_invite.page"))
);
const LeagueAbout = lazy(retryFailLoad(() => import("views/pages/league_about/league_about.page")));

const RegisterSuccessPage = lazy(
	retryFailLoad(() => import("views/pages/register_success/register_success.page"))
);

export const RootRoutes: React.FC = () => (
	<React.Fragment>
		<Routes>
			<Route
				element={
					<Fragment>
						<Header />
						<Outlet />
						<Footer />
					</Fragment>
				}>
				<Route path="/team" element={<Team />} />
				<Route path="/success-registration" element={<RegisterSuccessPage />} />
				<Route element={<NotAuthOnlyRoute />}>
					<Route index element={<LandingPage />} />
					<Route path="/forgot-password" element={<ForgotPassword />} />
					<Route path="/reset-password" element={<ResetPassword />} />
				</Route>
				<Route element={<PrivateRoute />}>
					<Route path="/team/:userId/:raceId" element={<RivalTeam />} />
					<Route path="/leaderboard" element={<Leaderboard />} />
					<Route path="/my-account" element={<MyAccount />} />
					<Route path="/leagues" element={<MyLeagues />} />
					<Route path="/leagues/join" element={<JoinLeagues />}>
						<Route path=":code" element={<JoinLeagues />} />
					</Route>
					<Route path="/leagues/create" element={<CreateLeague />} />
					<Route path="/league/:leagueId" element={<League />}>
						<Route path="tables" element={<LeagueTable />} />
						<Route path="invite" element={<LeagueInvite />} />
						<Route path="settings" element={<LeagueSettings />} />
						<Route path="about" element={<LeagueAbout />} />
					</Route>
				</Route>
				<Route path="components" element={<ComponentsReview />} />
				<Route path="help/*" element={<Help />} />
				<Route path="*" element={<NotFound />} />
			</Route>
		</Routes>
	</React.Fragment>
);

export default RootRoutes;
