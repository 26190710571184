import {
	makeAutoObservable,
	observable,
	IReactionDisposer,
	action,
	runInAction,
	reaction,
} from "mobx";
import {ViewController} from "data/types/structure";
import {injectable, inject} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IUserStore} from "data/stores/user/user.store";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import React from "react";
import type {IRegisterFormElement} from "data/types/forms";
import {ITermsPayload} from "data/providers/api/user.api.provider";
import {AxiosError} from "axios";
import type {IApiResponse} from "data/services/http";

export interface IModalTermsController extends ViewController {
	i18n: ILocalizationStore;
	get isOpen(): boolean;

	close: () => void;

	handleFormSubmit: (event: React.SyntheticEvent<IRegisterFormElement, Event>) => void;

	handleFormChange: () => void;

	get isLoading(): boolean;
	get errorMessage(): string | null;
}

@injectable()
export class ModalTermsController implements IModalTermsController {
	@observable _isOpen = false;
	@observable private subscriptions$: IReactionDisposer[] = [];
	@observable private _isLoading: boolean = false;
	@observable private _errorMsg: string | null = null;

	constructor(
		@inject(Bindings.LocalizationStore) public i18n: ILocalizationStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore
	) {
		makeAutoObservable(this);
	}

	get isOpen(): boolean {
		return this._isOpen;
	}

	public close = () => {
		this._isOpen = false;
	};

	get errorMessage() {
		return this._errorMsg;
	}

	get isLoading(): boolean {
		return this._isLoading;
	}

	@action
	public handleFormChange = () => {
		if (this._errorMsg) this._errorMsg = null;
		if (this._isLoading) this._isLoading = false;
	};

	public handleFormSubmit = (event: React.SyntheticEvent<IRegisterFormElement, Event>) => {
		event.preventDefault();

		const {terms, federations} = event.currentTarget;

		const payload: ITermsPayload = {
			terms: terms.checked,
			federations: federations.checked,
		};

		this._isLoading = true;
		this._userStore
			.terms(payload)
			.then(this.onSuccess)
			.catch(this.onError)
			.finally(() => {
				runInAction(() => {
					this._isLoading = false;
				});
			});
	};

	dispose(): void {
		this.subscriptions$.forEach((dispose) => dispose());
	}

	init(): void {
		this.subscriptions$ = [
			reaction(
				() => this._userStore.user,
				(user) => {
					if (user && !user?.terms) {
						this._isOpen = true;
					}
				}
			),
		];
	}

	@action
	private onSuccess = () => {
		this._isOpen = false;
	};

	@action
	private onError = (error: AxiosError<IApiResponse>) => {
		const errors = error.response?.data?.errors;
		this._errorMsg =
			errors?.map(({message}) => this.i18n.t(message, message)).join("<br/>") || "";
	};
}
