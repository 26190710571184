export enum RequestState {
	IDLE,
	PENDING,
	SUCCESS,
	ERROR,
}

export enum ModalType {
	ERROR,
	CONFIRM,
	REGISTER,
	LOGIN,
	CAPTAIN_PICK,
	DONKEY_INSURANCE,
	TEAM_SAVED,
	HORSE_INFO,
	TEAM_CHANGED,
	NO_RACES,
}

export enum SortOrder {
	ASC = "asc",
	DESC = "desc",
}
export enum RankingsSortValue {
	RacePoints = "race_points",
	OverallPoints = "overall_points",
}

export enum RoundStatus {
	Scheduled = "scheduled",
	Playing = "playing",
	Complete = "complete",
}

export enum SocialNetwork {
	Facebook,
	Twitter,
	Mobile,
}

export enum ShareType {
	General = "general",
	League = "league",
}

export enum Locale {
	EN_US = "en-US",
}

export enum Language {
	EN = "en",
}

export enum LeaguePrivacy {
	PUBLIC = "public",
	PRIVATE = "private",
}

export enum LeagueType {
	REGULAR = "regular",
	OVERALL = "overall",
}

export enum LeagueStatus {
	COMPLETE = "complete",
	PLAYING = "playing",
	SCHEDULED = "scheduled",
}

export enum AdvertType {
	Page = "page",
	Sidebar = "sidebar",
}

export enum RaceStatus {
	Scheduled = "scheduled",
	Playing = "playing",
	Complete = "complete",
	Abandoned = "abandoned",
}

export enum HorseStatus {
	Runner = "runner",
	NonRunner = "nonRunner",
}

export enum HorseStatsFilter {
	Position = "position",
	Selected = "selected",
	HsvAsc = "hsv-asc",
	HsvDesc = "hsv-desc",
}

export enum TeamPosition {
	FirstPlace = "firstPlace",
	SecondPlace = "secondPlace",
	ThirdPlace = "thirdPlace",
}

export enum TeamScoreDetails {
	Correct1st = "correct1st",
	Correct2nd = "correct2nd",
	Correct3rd = "correct3rd",
	Any1st = "any1st",
	Any2nd = "any2nd",
	Any3rd = "any3rd",
	Captain = "captain",
	Donkey = "donkey",
	Wd = "wd",
}
export enum HorseInfoBlock {
	Stats = "stats",
	Points = "points",
	Results = "results",
}

export enum TeamHorsePoints {
	FirstPlace = "1st",
	SecondPlace = "2nd",
	ThirdPlace = "3rd",
}

export enum YesNo {
	Yes = "yes",
	No = "no",
}

export enum TeamPageSection {
	Team = "team",
	Leaderboard = "leaderboard",
}

export enum ChecksumType {
	Common = "common",
	Fantasy = "fantasy",
}
