export const Bindings = {
	JSONProvider: Symbol("JSONProvider"),
	AuthApiProvider: Symbol("AuthApiProvider"),
	ApiHTTPClient: Symbol("ApiHTTPClient"),
	UserApiProvider: Symbol("UserApiProvider"),
	LeaguesApiProvider: Symbol("LeaguesApiProvider"),
	PasswordApiProvider: Symbol("PasswordApiProvider"),
	TeamApiProvider: Symbol("TeamApiProvider"),
	RankingsApiProvider: Symbol("RankingsApiProvider"),
	JsonHTTPClient: Symbol("JsonHTTPClient"),
	ContentJsonHTTPClient: Symbol("ContentJsonHTTPClient"),
	GeoApiProvider: Symbol("GeoApiProvider"),

	CountriesStore: Symbol("CountriesStore"),
	UserStore: Symbol("UserStore"),
	LocalizationStore: Symbol("LocalizationStore"),
	StaticContentStore: Symbol("StaticContentStore"),
	LeaguesStore: Symbol("LeaguesStore"),
	LeagueInvitesStore: Symbol("LeagueInvitesStore"),
	MenuStore: Symbol("MenuStore"),
	ChecksumStore: Symbol("ChecksumStore"),
	EventsStore: Symbol("EventsStore"),
	RacesStore: Symbol("RacesStore"),
	HorsesStore: Symbol("HorsesStore"),
	PlayerPoolStore: Symbol("PlayerPoolStore"),
	TeamStore: Symbol("TeamStore"),
	TutorialStore: Symbol("TutorialStore"),
	HorsePointsStore: Symbol("HorsePointsStore"),
	SecondOptStore: Symbol("SecondOptStore"),
	GeoBlockStore: Symbol("GeoBlockStore"),
	ModalsStore: Symbol("ModalsStore"),
	SettingsStore: Symbol("SettingsStore"),
	RankingsStore: Symbol("RankingsStore"),
	RivalTeamStore: Symbol("RivalTeamStore"),
	GameBarStore: Symbol("GameBarStore"),

	AuthController: Symbol("AuthController"),
	LoginController: Symbol("LoginController"),
	RegistrationController: Symbol("RegistrationController"),
	MyAccountController: Symbol("MyAccountController"),
	SecretGateController: Symbol("SecretGateController"),
	HelpController: Symbol("HelpController"),
	HelpListController: Symbol("HelpListController"),
	ContactUsController: Symbol("ContactUsController"),
	LiveScoreController: Symbol("LiveScoreController"),
	ForgotPasswordController: Symbol("ForgotPasswordController"),
	ResetPasswordController: Symbol("ResetPasswordController"),
	SessionController: Symbol("SessionController"),
	BootstrapController: Symbol("BootstrapController"),

	MyLeaguesController: Symbol("MyLeaguesController"),
	JoinLeaguesController: Symbol("JoinLeaguesController"),
	JoinLeaguesPageController: Symbol("JoinLeaguesPageController"),
	CreateLeagueController: Symbol("CreateLeagueController"),
	LeagueController: Symbol("LeagueController"),
	LeagueInviteController: Symbol("LeagueInviteController"),
	LeagueSettingsController: Symbol("LeagueSettingsController"),
	LeagueAboutController: Symbol("LeagueAboutController"),
	LeagueInviteFormController: Symbol("LeagueInviteFormController"),
	ModalErrorController: Symbol("ModalErrorController"),
	ModalConfirmController: Symbol("ModalConfirmController"),
	LocalizationController: Symbol("LocalizationController"),
	ModalAuthController: Symbol("ModalAuthController"),
	FormRegisterController: Symbol("FormRegisterController"),
	FormLoginController: Symbol("FormLoginController"),
	LandingController: Symbol("LandingController"),
	HeaderController: Symbol("HeaderController"),
	MenuController: Symbol("MenuController"),
	MenuAccordionController: Symbol("MenuAccordionController"),
	RangeSliderController: Symbol("RangeSliderController"),
	RoundSelectorController: Symbol("RoundSelectorController"),
	PlayerPoolController: Symbol("PlayerPoolController"),
	TeamPageController: Symbol("TeamPageController"),
	PlayerPoolRowController: Symbol("PlayerPoolRowController"),
	PlayerPoolFiltersController: Symbol("PlayerPoolFiltersController"),
	PlayerPoolActionsController: Symbol("PlayerPoolActionsController"),
	TeamHorseEmptyController: Symbol("TeamHorseEmptyController"),
	TeamHorseSelectedController: Symbol("TeamHorseSelectedController"),
	TieBreakerController: Symbol("TieBreakerController"),
	ModalCaptainPickController: Symbol("ModalCaptainPickController"),
	TeamActionsController: Symbol("TeamActionsController"),
	ModalDonkeyInsuranceController: Symbol("ModalDonkeyInsuranceController"),
	BoosterButtonController: Symbol("BoosterButtonController"),
	ModalTeamSavedController: Symbol("ModalTeamSavedController"),
	TickerController: Symbol("TickerController"),
	ModalTutorialController: Symbol("ModalTutorialController"),
	ModalHorseController: Symbol("ModalHorseController"),
	HorseInfoActionsController: Symbol("HorseInfoActionsController"),
	ModalTeamChangedController: Symbol("ModalTeamChangedController"),
	ModalLeaveNavigateController: Symbol("ModalLeaveNavigateController"),
	ResetPasswordPageController: Symbol("ResetPasswordPageController"),
	InvitedLeaguesController: Symbol("InvitedLeaguesController"),
	LeagueUsersController: Symbol("LeagueUsersController"),
	RacePointsController: Symbol("RacePointsController"),
	TieBreakerScoreController: Symbol("TieBreakerScoreController"),
	HorseInfoRacesController: Symbol("HorseInfoRacesController"),
	HorseInfoSingleRaceController: Symbol("HorseInfoSingleRaceController"),
	ModalLeagueInvitedController: Symbol("ModalLeagueInvitedController"),
	SectionLeagueSettingsController: Symbol("SectionLeagueSettingsController"),
	SectionLeagueLeaveController: Symbol("SectionLeagueLeaveController"),
	ModalSecondOptController: Symbol("ModalSecondOptController"),
	GeoBlockController: Symbol("GeoBlockController"),
	NotificationController: Symbol("NotificationController"),
	LeaderboardFiltersController: Symbol("LeaderboardFiltersController"),
	LeaderboardController: Symbol("LeaderboardController"),
	SharingController: Symbol("SharingController"),
	RivalTeamController: Symbol("RivalTeamController"),
	RivalHorseController: Symbol("RivalHorseController"),
	ModalNoRacesController: Symbol("ModalNoRacesController"),
	FormValidationHelper: Symbol("FormValidationHelper"),
	LeagueTableController: Symbol("LeagueTableController"),
	LeagueLeaderboardFilterController: Symbol("LeagueLeaderboardFilterController"),
	HorseInfoRaceResultController: Symbol("HorseInfoRaceResultController"),
	PlayerPoolActionsCompleteIconController: Symbol("PlayerPoolActionsCompleteIconController"),
	RaceScheduleController: Symbol("RaceScheduleController"),
	AdvertController: Symbol("AdvertController"),
	RaceScheduleStateController: Symbol("RaceScheduleStateController"),
	RegisterSuccessController: Symbol("RegisterSuccessController"),
	ModalPersonalizedController: Symbol("ModalPersonalizedController"),
	PlayerPoolBoosterActionsController: Symbol("PlayerPoolBoosterActionsController"),
	EventCountdownController: Symbol("EventCountdownController"),
	PreRegistrationFormController: Symbol("PreRegistrationFormController"),
	ModalTermsController: Symbol("ModalTermsController"),
};
