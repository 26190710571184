import React from "react";
import {observer} from "mobx-react";
import {Button, Modal, Typography} from "@mui/material";
import styled from "@emotion/styled";
import {useViewController} from "data/services/locator";
import type {IModalErrorController} from "views/components/modals/modal_error/modal_error.controller";
import {Bindings} from "data/constants/bindings";
import ErrorIcon from "@mui/icons-material/Error";
import CloseIcon from "@mui/icons-material/Close";
import {Exist} from "views/components/exist/exist.component";
import {ModalCloseBlock, ModalIcon} from "views/components/modals/styled";

const ModalContent = styled.div`
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	gap: 12px;
	outline: none;
	max-width: 600px;
	width: 100%;
	background: #fff;
	padding: 20px;
	border-radius: 4px;
	text-align: center;

	@media screen and (max-width: 640px) {
		max-width: unset;
		left: 24px;
		right: 24px;
		width: auto;
		transform: translateY(-50%);
	}

	button {
		max-width: 300px;
	}
`;

export const ModalError: React.FC = observer(() => {
	const {isOpen, modalContent, i18n, close} = useViewController<IModalErrorController>(
		Bindings.ModalErrorController
	);

	return (
		<Modal open={isOpen}>
			<ModalContent>
				<ModalCloseBlock>
					<Button aria-label="close" sx={{minWidth: 0}} onClick={close}>
						<CloseIcon />
					</Button>
				</ModalCloseBlock>
				<ModalIcon>
					<ErrorIcon />
				</ModalIcon>
				<Typography>
					{i18n.t(modalContent?.title ?? "modal.error.title", "Error")}
				</Typography>
				<Exist when={modalContent?.message}>
					<Typography>
						{i18n.t(
							modalContent?.message ?? "modal.error.title",
							modalContent?.message
						)}
					</Typography>
				</Exist>
				<Button onClick={close}>{i18n.t("modal.error.close", "Close")}</Button>
			</ModalContent>
		</Modal>
	);
});
