import {TeamHorsePoints, TeamPosition} from "data/enums";

export const API_URL = process.env.REACT_APP_API_URL || "";
export const JSON_URL = process.env.REACT_APP_JSON_URL || "";
export const CONTENT_JSON_URL = process.env.REACT_APP_CONTENT_JSON_URL || "";
export const BASE_URL = process.env.REACT_APP_BASE_URL || "/";
export const SENTRY_DSN_URL = process.env.REACT_APP_SENTRY_DSN_URL;
export const SHARE_URL = process.env.REACT_APP_SHARE_URL || "";
export const MEDIA_URL = process.env.REACT_APP_MEDIA_URL || "";
export const IS_AD_VISIBLE = process.env.REACT_APP_IS_AD_VISIBLE === "true";
export const AD_LINK = process.env.REACT_APP_AD_LINK || "";

export const EMAIL_REGEXP = "\\S+@\\S+\\.\\S+";
export const PASSWORD_PATTERN =
	"/^(?=.[a-z])(?=.[A-Z])(?=.\\d)(?=.[\\!£$%^&()_+?€@#='\":;\\/\\\\\\]\\[\\.,])[A-Za-z\\d\\!£$%^&()_+?€@#='\":;\\/\\\\\\]\\[\\.,]{8,}$/";

export const PASSWORD_REQUIREMENTS =
	"Password must contain at least 8 characters, 1 uppercase, 1 number and 1 special character";
export const OPT_IN_TEXT =
	"I confirm that I consent for my data and information to be shared with Crown Jewels Racing event partners and to receive marketing information from Crown Jewels Racing event partners.";

export const NO_TEAM_CODE = 400;
export const COUNTRY_BLOCKED_CODE = 423;
export const TEAM_HORSE_POSITIONS = [
	TeamPosition.FirstPlace,
	TeamPosition.SecondPlace,
	TeamPosition.ThirdPlace,
];

export const TIE_BREAKER_RANGE_MAPPER = {
	"-2": "0",
	"-1.5": "0.02",
	"-1": "0.05",
	"-0.5": "0.1",
	"0": "0.25",
};

export const TIE_BREAKER_RANGE_MAPPER_REVERSE = {
	"0": "-2",
	"0.02": "-1.5",
	"0.05": "-1",
	"0.1": "-0.5",
	"0.25": "0",
};

export const TIE_BREAKER_NAMES = {
	"0": "tiebreaker.value.dead_head",
	"0.02": "tiebreaker.value.nose",
	"0.05": "tiebreaker.value.short_head",
	"0.1": "tiebreaker.value.head",
	"0.25": "tiebreaker.value.neck",
};

export const POSITION_TO_POINTS_MAPPER = {
	[TeamPosition.FirstPlace]: TeamHorsePoints.FirstPlace,
	[TeamPosition.SecondPlace]: TeamHorsePoints.SecondPlace,
	[TeamPosition.ThirdPlace]: TeamHorsePoints.ThirdPlace,
};

export const TERMS_LINK = "/help/terms-&-conditions";
export const PRIVACY_LINK = "/help/privacy-notice";

export const TIE_BREAKER_STEP = 0.5;
export const TIE_BREAKER_MIN = -2;
export const TIE_BREAKER_MAX = 10.5;
export const TIE_BREAKER_CORRECT_POINTS = 50;
export const DONKEY_INSURANCE_TOTAL = 1;
export const DEFAULT_RANKINGS_LIMIT = 50;
export const MINIMUM_BOOSTER_HORSE_COUNT = 1;
export const MINIMUM_BOOSTER_HORSES_LIST_LENGTH = 8;
export const FORM_VALIDATION_ELEMENT_CLASSNAME = "form-field-validate" as const;

export const UAT_SITE_DOMAIN = "https://hba-fantasy.uat.f2p.media.geniussports.com";
export const PREPROD_SITE_DOMAIN = "https://hba-fantasy.preprod.f2p.media.geniussports.com";
export const PROD_SITE_DOMAIN = "https://fantasy.crownjewelsracing.com";
export const OTHER_ALLOWED_DOMAINS = "*.f2p.media.geniussports.com";
