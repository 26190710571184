import type {ComponentType} from "react";
import type {AxiosError} from "axios";
import type {IApiResponse} from "data/services/http";
import {MEDIA_URL} from "data/constants";

type Factory<T> = () => Promise<{
	default: ComponentType<T>;
}>;

export function retryFailLoad<T>(fn: Factory<T>, retriesLeft = 5, interval = 1000): Factory<T> {
	return () =>
		new Promise((resolve, reject) => {
			fn()
				.then(resolve)
				.catch((error: unknown) => {
					setTimeout(() => {
						if (retriesLeft === 1) {
							reject(error);
							return;
						}

						retryFailLoad(fn, retriesLeft - 1, interval)().then(resolve, reject);
					}, interval);
				});
		});
}

export const extractErrorMessage = (error: AxiosError<IApiResponse>, dangerouslyHtml = false) => {
	const tag = dangerouslyHtml ? " " : "\n";
	const messages = error.response?.data?.errors?.flatMap((e) => e.message).join(tag);

	return messages || error.message;
};

export const copyToClipboard = async (content: string) => {
	try {
		await navigator.clipboard.writeText(content);
		return true;
	} catch (_err) {
		return false;
	}
};

export function getDashedValue<T>(value: T, replace: string = "-"): string {
	if (value === undefined || value === null) {
		return replace;
	}
	return String(value);
}

export function keyOrEmpty(key: string | undefined): string {
	return key || "";
}

export function getJerseyImage(url: string): string {
	return `${MEDIA_URL}jerseys/${url}`;
}

export function isNullOrUndefined<T>(value: T): boolean {
	return value === null || value === undefined;
}

export function getEventImage(url: string | null): string {
	if (!url) {
		return "";
	}
	return `${MEDIA_URL}event/logo/${url}`;
}

export function getEventImageGrayScale(url: string | null): string {
	if (!url) {
		return "";
	}
	return `${MEDIA_URL}races/grayscale/${url}`;
}

export function prepareName(name: string): string {
	const names = name.split(" ");

	if (["miss", "mr", "ms"].some((e) => name.toLowerCase().startsWith(e))) {
		return prepareNameWithMiss(name);
	}

	if (names.length > 2 || names[0].length <= 2) {
		return name;
	}

	const firstName = names[0];
	const lastName = names[1];

	return `${firstName.charAt(0)}. ${lastName}`;
}

export function prepareNameWithMiss(name: string): string {
	const names = name.split(" ");
	if (names.length !== 3 || names[1].length <= 2) {
		return name;
	}

	const prefix = names[0];
	const firstName = names[1];
	const lastName = names[2];

	return `${prefix} ${firstName.charAt(0)}. ${lastName}`;
}

export function getIsIOS() {
	if (!navigator) {
		return false;
	}
	return (
		["iPad Simulator", "iPhone Simulator", "iPod Simulator", "iPad", "iPhone", "iPod"].includes(
			navigator.platform
		) ||
		// iPad on iOS 13 detection
		(navigator.userAgent.includes("Mac") && "ontouchend" in document)
	);
}

export function numberSuffix(value: number) {
	const delimiterTen = value % 10;
	const delimiterHundred = value % 100;

	if (delimiterTen === 1 && delimiterHundred !== 11) {
		return `${value}st`;
	}
	if (delimiterTen === 2 && delimiterHundred !== 12) {
		return `${value}nd`;
	}
	if (delimiterTen === 3 && delimiterHundred !== 13) {
		return `${value}rd`;
	}
	return `${value}th`;
}

export function getIsWindows() {
	return navigator.platform.indexOf("Win") > -1 ? "no-scroll-bar" : "";
}

export * from "data/utils/countdown";
export * from "data/utils/socialShare";
