import {BASE_URL} from "data/constants";

export abstract class ModalHelper {
	static STORE_KEY = `${BASE_URL}/modal_personalized`;

	static get() {
		return localStorage.getItem(ModalHelper.STORE_KEY);
	}

	static set(value: string) {
		localStorage.setItem(ModalHelper.STORE_KEY, value);
	}

	static remove() {
		localStorage.removeItem(ModalHelper.STORE_KEY);
	}
}
