import {makeAutoObservable, observable, action, runInAction} from "mobx";
import {ViewController} from "data/types/structure";
import {injectable, inject} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IUserStore} from "data/stores/user/user.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import React from "react";
import type {IPreRegisterFormElement} from "data/types/forms";
import {AxiosError} from "axios";
import {IApiResponse} from "data/services/http";
import {extractErrorMessage} from "data/utils";
import {IPreRegisterPayload} from "data/providers/api/user.api.provider";

export interface IPreRegistrationFormController extends ViewController {
	i18n: ILocalizationStore;
	handleFormSubmit: (event: React.SyntheticEvent<IPreRegisterFormElement>) => void;
	handleFormChange: (e: React.FormEvent<IPreRegisterFormElement>) => void;

	get isLoading(): boolean;

	get isValid(): boolean;

	get isSuccess(): boolean;

	get errorMsg(): string | null;
}

@injectable()
export class PreRegistrationFormController implements IPreRegistrationFormController {
	@observable _isSuccess = false;
	@observable _isValid = false;
	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.ModalsStore) private readonly _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	@observable private _errorMsg: string | null = null;
	@observable private _isLoading: boolean = false;

	get isSuccess(): boolean {
		return this._isSuccess;
	}
	get errorMsg(): string | null {
		return this._errorMsg;
	}

	get isLoading(): boolean {
		return this._isLoading;
	}

	get isValid(): boolean {
		return this._isValid;
	}

	@action
	public handleFormChange = (event: React.FormEvent<IPreRegisterFormElement>) => {
		const {email, firstName} = event.currentTarget;
		this._isValid = Boolean(email.value && firstName.value);
		if (this._errorMsg) this._errorMsg = null;
		if (this._isLoading) this._isLoading = false;
	};

	@action
	public handleFormSubmit = (event: React.SyntheticEvent<IPreRegisterFormElement>) => {
		event.preventDefault();
		const {email, firstName} = event.currentTarget;

		const payload: IPreRegisterPayload = {
			email: email.value,
			name: firstName.value,
		};

		this._isLoading = true;
		this._userStore
			.preRegister(payload)
			.then(this.onSuccess)
			.catch(this.onError)
			.finally(() => {
				runInAction(() => {
					this._isLoading = false;
				});
			});
	};

	dispose(): void {
		return;
	}

	init(param: void): void {
		return;
	}

	@action
	private onSuccess = () => {
		this._isSuccess = true;
	};

	@action
	private onError = (error: AxiosError<IApiResponse>) => {
		this._errorMsg = extractErrorMessage(error);
	};
}
