import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {makeAutoObservable} from "mobx";
import {Bindings} from "data/constants/bindings";
import {ModalType, RaceStatus} from "data/enums";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IHorsesStore} from "data/stores/horses/horses.store";
import type {IEventsStore} from "data/stores/events/events.store";
import type {ITeamStore} from "data/stores/team/team.store";
import {MINIMUM_BOOSTER_HORSES_LIST_LENGTH} from "data/constants";
import type {ITeam} from "data/types/team";
import type {IRacesStore} from "data/stores/races/races.store";

export interface IModalTeamSavedController extends ViewController {
	get i18n(): ILocalizationStore;
	get isOpen(): boolean;
	get isWithoutBoosterCopy(): boolean;
	get isDisabledBooster(): boolean;
	close: () => void;
	openBooster: () => void;
	openSchedule: () => void;
}

@injectable()
export class ModalTeamSavedController implements IModalTeamSavedController {
	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.HorsesStore) private _horsesStore: IHorsesStore,
		@inject(Bindings.EventsStore) private readonly _eventsStore: IEventsStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.TeamStore) private _teamStore: ITeamStore,
		@inject(Bindings.RacesStore) private _racesStore: IRacesStore
	) {
		makeAutoObservable(this);
	}

	dispose(): void {
		this._teamStore.setModalInQueue(false);
	}

	init(param: void): void {
		return;
	}

	onChange(param: void): void {
		return;
	}

	public close = () => {
		this._modalsStore.hideModal();
		this._teamStore.setModalInQueue(false);
	};

	public openBooster = () => {
		this.close();
		this._modalsStore.showModal(ModalType.DONKEY_INSURANCE);
	};

	public openSchedule = () => {
		this.close();
		this._eventsStore.isScheduleOpen = true;
	};

	get isOpen(): boolean {
		return this._modalsStore.modal === ModalType.TEAM_SAVED;
	}

	get isWithoutBoosterCopy(): boolean {
		return this._horsesStore.isNoHorsesForBooster || this.isDisabledBooster;
	}

	get isDisabledBooster() {
		return !this.isInfo && this.horses.length <= MINIMUM_BOOSTER_HORSES_LIST_LENGTH;
	}

	get isInfo(): boolean {
		if (!this.team.donkeyInsurance) {
			return !this.team.donkeyInsuranceAllowedCount;
		}
		return !!this.team.donkeyInsurance && this.isLocked;
	}

	private get team() {
		return this._teamStore.team as ITeam;
	}

	private get horses() {
		return this._horsesStore.list;
	}

	private get isLocked() {
		if (!this.team.race) {
			return true;
		}
		const teamRace = this._racesStore.getRaceById(this.team.race);
		return teamRace?.status !== RaceStatus.Scheduled;
	}
}
