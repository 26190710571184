import React, {useEffect} from "react";
import {observer} from "mobx-react";
import {useViewController} from "data/services/locator";
import {IMenuController} from "views/components/menu/menu.controller";
import {Bindings} from "data/constants/bindings";
import styled from "@emotion/styled";
import {NavLink} from "react-router-dom";
import {Exist} from "views/components/exist/exist.component";
import {useLocation} from "react-router";
import {Button} from "@mui/material";
import {MenuAccordion} from "views/components/menu_accordion/menu_accordion.component";

const Wrapper = styled.div`
	background: #ffffff;
	position: fixed;
	left: 0;
	top: 56px;
	right: 0;
	bottom: 0;
	z-index: 99;
	transform: translateX(-100%);
	transition: 0.4s;

	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	overscroll-behavior: none;
	overflow: scroll;

	&.open {
		transform: translateX(0);
	}

	> a,
	> button {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		border-bottom: 1px solid var(--common-borders);

		color: var(--primaryColorDark);
		font-size: 14px;
		font-style: normal;
		font-weight: 700;
		line-height: 140%;
		padding: 14px 20px;

		&.active {
			background: var(--iconColorMedium);
		}
	}

	button:last-of-type {
		border-color: transparent;
	}
`;

export const Menu: React.FC = observer(() => {
	const location = useLocation();
	const {i18n, isOpen, isAuthorized, closeMenu, logout} = useViewController<IMenuController>(
		Bindings.MenuController
	);
	const className = isOpen ? "open" : "";

	useEffect(() => {
		document.body.classList.toggle("fixed-scroll", isOpen);
	}, [isOpen]);
	useEffect(() => {
		closeMenu();
	}, [location, closeMenu]);

	return (
		<Wrapper className={className} onScroll={(e) => e.stopPropagation()}>
			<Exist when={!isAuthorized}>
				<NavLink to="/team">{i18n.t("header.navigation.home", "Home")}</NavLink>
				<NavLink to="/help/prizes">{i18n.t("header.navigation.prizes", "Prizes")}</NavLink>
				<NavLink end to="/help">
					{i18n.t("header.navigation.help", "Help")}
				</NavLink>
			</Exist>

			<Exist when={isAuthorized}>
				<NavLink to="/team">{i18n.t("header.navigation.home", "Home")}</NavLink>
				<MenuAccordion
					pathName="/leagues"
					link={
						<NavLink to="/leagues">
							{i18n.t("header.navigation.leagues", "Leagues")}
						</NavLink>
					}>
					<NavLink to="/leagues/create">
						{i18n.t("header.navigation.leagues_create", "Create a League")}
					</NavLink>
					<NavLink to="/leagues/join">
						{i18n.t("header.navigation.leagues_join", "Join a League")}
					</NavLink>
				</MenuAccordion>

				<NavLink end to="/help/prizes">
					{i18n.t("header.navigation.prizes", "Prizes")}
				</NavLink>
				<NavLink to="/help">{i18n.t("header.navigation.help", "Help")}</NavLink>
				<NavLink to="/my-account">
					{i18n.t("header.navigation.account", "My Account")}
				</NavLink>
				<Button variant="text" onClick={logout}>
					{i18n.t("header.navigation.log_out", "Log Out")}
				</Button>
			</Exist>
		</Wrapper>
	);
});
