import {makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {injectable, inject} from "inversify";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {Bindings} from "data/constants/bindings";
import type {IRacesStore} from "data/stores/races/races.store";
import type {IEventsStore, IEvent} from "data/stores/events/events.store";
import {IRace} from "data/types/race";
import {first} from "lodash";
import type {ISettingsStore} from "data/stores/settings/settings.store";

export interface IEventCountdownController extends ViewController {
	i18n: ILocalizationStore;

	get race(): IRace | undefined;
	get event(): IEvent | undefined;
}

@injectable()
export class EventCountdownController implements IEventCountdownController {
	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.RacesStore) private _racesStore: IRacesStore,
		@inject(Bindings.EventsStore) private _eventsStore: IEventsStore,
		@inject(Bindings.SettingsStore) private _settingsStore: ISettingsStore
	) {
		makeAutoObservable(this);
	}

	get race() {
		return first(this._racesStore.scheduledList);
	}

	get event() {
		const nextRace = this._settingsStore.settings?.nextRace;
		if (nextRace?.trim().length) {
			return this._eventsStore.getEventByName(nextRace);
		}

		const futureRace = this._eventsStore.events.find(
			(it) => new Date(it.date).getTime() > new Date().getTime()
		);
		if (futureRace) {
			return futureRace;
		}

		return first(this._eventsStore.events);
	}

	init(param: void) {
		void this._eventsStore.fetchEvents();
	}
}
