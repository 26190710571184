import {makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {injectable, inject} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IUserStore} from "data/stores/user/user.store";
import type {ILocalizationStore} from "data/stores/localization/localization.store";

export interface IRegisterSuccessController extends ViewController {
	i18n: ILocalizationStore;
	get isVisible(): boolean;
}

@injectable()
export class RegisterSuccessController implements IRegisterSuccessController {
	constructor(
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.LocalizationStore) public i18n: ILocalizationStore
	) {
		makeAutoObservable(this);
	}

	get isVisible() {
		return this._userStore.isSuccessRegister;
	}

	dispose() {
		this._userStore.isSuccessRegister = false;
	}
}
