import {retryFailLoad} from "data/utils";
import React, {lazy, Fragment} from "react";
import {Route, Routes, Outlet} from "react-router-dom";
import {Header} from "views/components/header/header.component";
import {Footer} from "views/components/footer/footer.component";

const SecretLanding = lazy(
	retryFailLoad(() => import("views/pages/secret_landing/secret_landing.page"))
);
const Help = lazy(retryFailLoad(() => import("views/pages/help/help.page")));

export const SecretGateRoutes: React.FC = () => (
	<Routes>
		<Route
			element={
				<Fragment>
					<Header />
					<Outlet />
					<Footer />
				</Fragment>
			}>
			<Route path="help/*" element={<Help />} />
			<Route path="*" element={<SecretLanding />} />
		</Route>
	</Routes>
);

export default SecretGateRoutes;
