import React from "react";
import {observer} from "mobx-react";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {type IModalPersonalizedController} from "views/components/modals/modal_personalized/modal_personalized.controller";
import {ModalContent, ModalCloseBlock} from "views/components/modals/styled";
import {Button, Modal, Stack} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import styled from "@emotion/styled";
import {MEDIA_URL} from "data/constants";

const SModalIcon = styled.div`
	max-width: 200px;
	img {
		width: 100%;
	}
`;

const Title = styled.div`
	color: #002f1a;
	text-align: center;
	font-size: 20px;
	font-style: normal;
	font-weight: 700;
	line-height: 140%;
	text-transform: capitalize;
`;

const Body = styled.div`
	font-weight: 450;
	font-size: 16px;
	color: #002f1a;
	text-align: center;
	font-style: normal;
	line-height: 140%;
`;

export const ModalPersonalized: React.FC = observer(() => {
	const {isOpen, personalizedModal, closeModal} = useViewController<IModalPersonalizedController>(
		Bindings.ModalPersonalizedController
	);

	if (!personalizedModal) {
		return null;
	}

	return (
		<Modal
			open={isOpen}
			disablePortal={true}
			disableRestoreFocus={true}
			disableEnforceFocus={true}>
			<ModalContent>
				<ModalCloseBlock>
					<Button aria-label="close" sx={{minWidth: 0}} onClick={closeModal}>
						<CloseIcon />
					</Button>
				</ModalCloseBlock>
				<SModalIcon>
					<img src={`${MEDIA_URL}modal/${personalizedModal.modal.logo}`} alt="" />
				</SModalIcon>
				<Stack gap={1} pb={1.5}>
					<Title>{personalizedModal.modal.title}</Title>
					<Body>{personalizedModal.modal.body}</Body>
				</Stack>
			</ModalContent>
		</Modal>
	);
});
