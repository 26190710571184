import {inject, injectable} from "inversify";
import type {IApiResponse, IHttpClientService} from "data/services/http";
import type {AxiosResponse} from "axios";
import {Bindings} from "data/constants/bindings";

export type TResponse = IApiResponse<{showBanner: boolean}>;

export interface IGeoApiProvider {
	geoFence: () => Promise<AxiosResponse<TResponse>>;
}

@injectable()
export class GeoApiProvider implements IGeoApiProvider {
	constructor(@inject(Bindings.ApiHTTPClient) private _http: IHttpClientService) {}

	geoFence = () => this._http.get<TResponse>("geo_fence");
}
