import {Container, ContainerModule, type interfaces} from "inversify";
import {Bindings} from "data/constants/bindings";
import {type IUserStore, UserStore} from "data/stores/user/user.store";
import {AuthController, type IAuthController} from "views/controllers/auth/auth.controller";
import {
	type IMyAccountController,
	MyAccountController,
} from "views/pages/my_account/my_account.controller";
import {API_URL, CONTENT_JSON_URL, JSON_URL} from "data/constants";
import {type IJSONProvider, JSONProvider} from "data/providers/json/json.provider";
import {AuthApiProvider, type IAuthApiProvider} from "data/providers/api/auth.api.provider";
import {HttpClientFactory, type IHttpClientService} from "data/services/http";
import {CountriesStore, type ICountriesStore} from "data/stores/countries/countries.store";
import {SecretGateController} from "views/controllers/secret_gate/secret_gate.controller";
import {
	type ILocalizationStore,
	LocalizationStore,
} from "data/stores/localization/localization.store";
import {
	type IStaticContentStore,
	StaticContentStore,
} from "data/stores/static_content/static_content.store";
import {HelpController, type IHelpController} from "views/pages/help/help.controller";
import {
	HelpListController,
	type IHelpListController,
} from "views/components/help/help_list/help_list.controller";
import {
	ContactUsController,
	type IContactUsController,
} from "views/components/help/contact_us/contact_us.controller";
import {
	type ILiveScoreController,
	LiveScoreController,
} from "views/controllers/live_score/live_score.controller";
import {ChecksumStore, type IChecksumStore} from "data/stores/checksum/checksum.store";
import {type IUserApiProvider, UserApiProvider} from "data/providers/api/user.api.provider";
import {
	type IPasswordApiProvider,
	PasswordApiProvider,
} from "data/providers/api/password.api.provider";
import {
	ForgotPasswordController,
	type IForgotPasswordController,
} from "views/pages/forgot_password/forgot_password.controller";
import {
	type IResetPasswordController,
	ResetPasswordController,
} from "views/components/reset_password/reset_password.controller";
import {
	type ISessionController,
	SessionController,
} from "views/components/session/session.controller";
import {
	BootstrapController,
	type IBootstrapController,
} from "views/components/bootstrap/bootstrap.controller";
import {
	type ILeaguesApiProvider,
	LeaguesApiProvider,
} from "data/providers/api/leagues.api.provider";
import {type ILeaguesStore, LeaguesStore} from "data/stores/leagues/leagues.store";
import {
	CreateLeagueController,
	type ICreateLeagueController,
} from "views/pages/create_league/create_league.controller";
import {type ILeagueController, LeagueController} from "views/pages/league/league.controller";
import {
	type ILeagueInviteController,
	LeagueInviteController,
} from "views/pages/league_invite/league_invite.controller";
import {
	type ILeagueSettingsController,
	LeagueSettingsController,
} from "views/pages/league_settings/league_settings.controller";
import {
	type ILeagueAboutController,
	LeagueAboutController,
} from "views/pages/league_about/league_about.controller";
import {
	type ILeagueInviteFormController,
	LeagueInviteFormController,
} from "views/components/league_invite_form/league_invite_form.controller";
import {type IModalsStore, ModalsStore} from "data/stores/modals/modals.store";
import {
	type IModalErrorController,
	ModalErrorController,
} from "views/components/modals/modal_error/modal_error.controller";
import {
	type IModalConfirmController,
	ModalConfirmController,
} from "views/components/modals/modal_confirm/modal_confirm.controller";
import {
	type ILocalizationController,
	LocalizationController,
} from "views/controllers/localization/localization.controller";
import {
	IModalAuthController,
	ModalAuthController,
} from "views/components/modals/modal_auth/modal_auth.controller";
import {
	FormRegisterController,
	IFormRegisterController,
} from "views/components/forms/form_register/form_register.controller";
import {ILandingController, LandingController} from "views/pages/landing/landing.controller";
import {
	FormLoginController,
	IFormLoginController,
} from "views/components/forms/form_login/form_login.controller";
import {HeaderController, IHeaderController} from "views/components/header/header.controller";
import {IMenuStore, MenuStore} from "data/stores/menu/menu.store";
import {IMenuController, MenuController} from "views/components/menu/menu.controller";
import {
	IMenuAccordionController,
	MenuAccordionController,
} from "views/components/menu_accordion/menu_accordion.controller";
import {EventsStore, IEventsStore} from "data/stores/events/events.store";
import {
	IRangeSliderController,
	RangeSliderController,
} from "views/components/range_slider/range_slider.controller";
import {IRacesStore, RacesStore} from "data/stores/races/races.store";
import {
	IRoundSelectorController,
	RoundSelectorController,
} from "views/components/round_selector/round_selector.controller";
import {
	IPlayerPoolController,
	PlayerPoolController,
} from "views/components/player_pool/player_pool.controller";
import {ITeamPageController, TeamPageController} from "views/pages/team/team.controller";
import {HorsesStore, IHorsesStore} from "data/stores/horses/horses.store";
import {
	IPlayerPoolRowController,
	PlayerPoolRowController,
} from "views/components/player_pool_row/player_pool_row.controller";
import {IPlayerPoolStore, PlayerPoolStore} from "data/stores/player_pool/player_pool.store";
import {
	ITeamHorseEmptyController,
	TeamHorseEmptyController,
} from "views/components/team_horse_empty/team_horse_empty.controller";
import {
	IPlayerPoolFiltersController,
	PlayerPoolFiltersController,
} from "views/components/player_pool_filters/player_pool_filters.controller";
import {ITeamStore, TeamStore} from "data/stores/team/team.store";
import {ITeamApiProvider, TeamApiProvider} from "data/providers/api/team.api.provider";
import {
	IPlayerPoolActionsController,
	PlayerPoolActionsController,
} from "views/components/player_pool_actions/player_pool_actions.controller";
import {
	ITeamHorseSelectedController,
	TeamHorseSelectedController,
} from "views/components/team_horse_selected/team_horse_selected.controller";
import {
	ITieBreakerController,
	TieBreakerController,
} from "views/components/tie_breaker/tie_breaker.controller";
import {
	IModalCaptainPickController,
	ModalCaptainPickController,
} from "views/components/modals/modal_captain_pick/modal_captain_pick.controller";
import {
	ITeamActionsController,
	TeamActionsController,
} from "views/components/team_actions/team_actions.controller";
import {
	IModalDonkeyInsuranceController,
	ModalDonkeyInsuranceController,
} from "views/components/modals/modal_donkey_insurance/modal_donkey_insurance.controller";
import {
	BoosterButtonController,
	IBoosterButtonController,
} from "views/components/booster_button/booster_button.controller";
import {
	IModalTeamSavedController,
	ModalTeamSavedController,
} from "views/components/modals/modal_team_saved/modal_team_saved.controller";
import {ITickerController, TickerController} from "views/components/ticker/ticker.controller";
import {
	IModalTutorialController,
	ModalTutorialController,
} from "views/components/modals/modal_tutorial/modal_tutorial.controller";
import {ITutorialStore, TutorialStore} from "data/stores/tutorial/tutorial.store";
import {
	IModalHorseController,
	ModalHorseController,
} from "views/components/modals/modal_horse_info/modal_horse_info.controller";
import {
	HorseInfoActionsController,
	IHorseInfoActionsController,
} from "views/components/horse_info_actions/horse_info_actions.controller";
import {
	IModalTeamChangedController,
	ModalTeamChangedController,
} from "views/components/modals/modal_team_changed/modal_team_changed.controller";
import {
	IModalLeaveNavigateController,
	ModalLeaveNavigateController,
} from "views/components/modals/modal_leave_navigate/modal_leave_navigate.controller";
import {
	IResetPasswordPageController,
	ResetPasswordPageController,
} from "views/pages/reset_password/reset_password.controller";
import {
	IMyLeaguesController,
	MyLeaguesController,
} from "views/components/my_leagues/my_leagues.controller";
import {
	IJoinLeaguesController,
	JoinLeaguesController,
} from "views/components/join_leagues/join_leagues.controller";
import {
	IJoinLeaguesPageController,
	JoinLeaguesPageController,
} from "views/pages/join_leagues/join_leagues_page.controller";
import {
	IInvitedLeaguesController,
	InvitedLeaguesController,
} from "views/components/invited_leagues/invited_leagues.controller";
import {
	ILeagueUsersController,
	LeagueUsersController,
} from "views/components/league_users/league_users.controller";
import {
	IRacePointsController,
	RacePointsController,
} from "views/components/race_points/race_points.controller";
import {
	ITieBreakerScoreController,
	TieBreakerScoreController,
} from "views/components/tie_breaker_score/tie_breaker_score.controller";
import {
	HorseInfoRacesController,
	IHorseInfoRacesController,
} from "views/components/horse_info_races/horse_info_races.controller";
import {
	HorseInfoSingleRaceController,
	IHorseInfoSingleRaceController,
} from "views/components/horse_info_single_race/horse_info_single_race.controller";
import {
	ILeagueInvitesStore,
	LeagueInvitesStore,
} from "data/stores/leagues_invites/leagues_invites.store";
import {
	IModalLeagueInvitedController,
	ModalLeagueInvitedController,
} from "views/components/modals/modal_league_invited/modal_league_invited.controller";
import {
	ISectionLeagueSettingsController,
	SectionLeagueSettingsController,
} from "views/components/section_league_settings/section_league_settings.controller";
import {
	ISectionLeagueLeaveController,
	SectionLeagueLeaveController,
} from "views/components/section_league_leave/section_league_leave.controller";
import {HorsePointsStore, IHorsePointsStore} from "data/stores/horse_points/horse_points.store";
import {
	IModalSecondOptController,
	ModalSecondOptController,
} from "views/components/modals/modal_second_opt/modal_second_opt.controller";
import {
	GeoBlockController,
	IGeoBlockController,
} from "views/components/geo_block/geo_block.controller";
import {ISecondOptStore, SecondOptStore} from "data/stores/second_opt/second_opt.store";
import {GeoBlockStore, IGeoBlockStore} from "data/stores/geo_block/geo_block.store";
import {ISettingsStore, SettingsStore} from "data/stores/settings/settings.store";
import {
	INotificationController,
	NotificationController,
} from "views/components/notification/notification.controller";
import {IRankingsApiProvider, RankingsApiProvider} from "data/providers/api/rankings.api.provider";
import {IRankingsStore, RankingsStore} from "data/stores/rankings/rankings.store";
import {
	ILeaderboardFiltersController,
	LeaderboardFiltersController,
} from "views/components/leaderboard_filters/leaderboard_filters.controller";
import {
	ILeaderboardController,
	LeaderboardController,
} from "views/components/leaderboard/leaderboard.controller";
import {ISharingController, SharingController} from "views/components/sharing/sharing.controller";
import {RivalTeamStore, IRivalTeamStore} from "data/stores/rival_team/rival_team.store";
import {
	IRivalHorseController,
	RivalHorseController,
} from "views/components/rival_horse/rival_horse.controller";
import {
	IRivalTeamController,
	RivalTeamController,
} from "views/pages/rival_team/rival_team.controller";
import {
	IModalNoRacesController,
	ModalNoRacesController,
} from "views/components/modals/modal_no_races/modal_no_races.controller";
import {FormValidationHelper, IFormValidationHelper} from "data/utils/form_validation_helper";
import {ViewController} from "data/types/structure";
import {
	ILeagueTableController,
	LeagueTableController,
} from "views/pages/league_table/league_table.controller";
import {
	ILeagueLeaderboardFilterController,
	LeagueLeaderboardFilterController,
} from "views/components/league_leaderboard_filters/league_leaderboard_filters.controller";
import {
	HorseInfoRaceResultController,
	IHorseInfoRaceResultController,
} from "views/components/horse_info_race_result/horse_info_race_result.controller";
import {GameBarStore, IGameBarStore} from "data/stores/game_bar/game_bar.store";
import {
	IPlayerPoolActionsCompleteIconController,
	PlayerPoolActionsCompleteIconController,
} from "views/components/player_pool_actions_complete_icon/player_pool_actions_complete_icon.controller";
import {
	IRaceScheduleController,
	RaceScheduleController,
} from "views/components/race_schedule/race_schedule.controller";
import {
	IRaceScheduleStateController,
	RaceScheduleStateController,
} from "views/components/race_schedule_state/race_schedule_state.controller";
import {IAdvertController, AdvertController} from "views/components/advert/advert.controller";
import {IGeoApiProvider, GeoApiProvider} from "data/providers/api/geo.api.provider";
import {
	type IRegisterSuccessController,
	RegisterSuccessController,
} from "views/pages/register_success/register_success.controller";
import {
	type IModalPersonalizedController,
	ModalPersonalizedController,
} from "views/components/modals/modal_personalized/modal_personalized.controller";
import {
	IPlayerPoolBoosterActionsController,
	PlayerPoolBoosterActionsController,
} from "views/components/player_pool_booster_actions/player_pool_booster_actions.controller";
import {
	type IEventCountdownController,
	EventCountdownController,
} from "views/components/landing/event_countdown/event_countdown.controller";
import {
	IPreRegistrationFormController,
	PreRegistrationFormController,
} from "views/components/pre_registration_form/pre_registration_form.controller";
import {
	type IModalTermsController,
	ModalTermsController,
} from "views/components/modals/modal_terms/modal_terms.controller";

export const DIContainer = new Container();

export const services = new ContainerModule((bind: interfaces.Bind) => {
	bind<IHttpClientService>(Bindings.ApiHTTPClient).toConstantValue(
		HttpClientFactory.createApiClient({
			baseURL: API_URL,
			withCredentials: true,
		})
	);

	bind<IHttpClientService>(Bindings.JsonHTTPClient).toConstantValue(
		HttpClientFactory.createJSONClient({
			baseURL: JSON_URL,
		})
	);

	bind<IHttpClientService>(Bindings.ContentJsonHTTPClient).toConstantValue(
		HttpClientFactory.createJSONClient({
			baseURL: CONTENT_JSON_URL,
		})
	);
});

export const providers = new ContainerModule((bind: interfaces.Bind) => {
	bind<IJSONProvider>(Bindings.JSONProvider).to(JSONProvider);
	bind<IAuthApiProvider>(Bindings.AuthApiProvider).to(AuthApiProvider);
	bind<IUserApiProvider>(Bindings.UserApiProvider).to(UserApiProvider);
	bind<IPasswordApiProvider>(Bindings.PasswordApiProvider).to(PasswordApiProvider);
	bind<ILeaguesApiProvider>(Bindings.LeaguesApiProvider).to(LeaguesApiProvider);
	bind<ITeamApiProvider>(Bindings.TeamApiProvider).to(TeamApiProvider);
	bind<IRankingsApiProvider>(Bindings.RankingsApiProvider).to(RankingsApiProvider);
	bind<IGeoApiProvider>(Bindings.GeoApiProvider).to(GeoApiProvider);
});

export const stores = new ContainerModule((bind: interfaces.Bind) => {
	bind<ILocalizationStore>(Bindings.LocalizationStore).to(LocalizationStore).inSingletonScope();
	bind<IUserStore>(Bindings.UserStore).to(UserStore).inSingletonScope();

	bind<ICountriesStore>(Bindings.CountriesStore).to(CountriesStore);
	bind<IStaticContentStore>(Bindings.StaticContentStore).to(StaticContentStore);

	bind<IChecksumStore>(Bindings.ChecksumStore).to(ChecksumStore);
	bind<ILeaguesStore>(Bindings.LeaguesStore).to(LeaguesStore).inSingletonScope();
	bind<ILeagueInvitesStore>(Bindings.LeagueInvitesStore)
		.to(LeagueInvitesStore)
		.inSingletonScope();
	bind<IModalsStore>(Bindings.ModalsStore).to(ModalsStore).inSingletonScope();
	bind<IMenuStore>(Bindings.MenuStore).to(MenuStore).inSingletonScope();
	bind<IEventsStore>(Bindings.EventsStore).to(EventsStore).inSingletonScope();
	bind<IRacesStore>(Bindings.RacesStore).to(RacesStore).inSingletonScope();
	bind<IHorsesStore>(Bindings.HorsesStore).to(HorsesStore).inSingletonScope();
	bind<IPlayerPoolStore>(Bindings.PlayerPoolStore).to(PlayerPoolStore).inSingletonScope();
	bind<ITeamStore>(Bindings.TeamStore).to(TeamStore).inSingletonScope();
	bind<ITutorialStore>(Bindings.TutorialStore).to(TutorialStore).inSingletonScope();
	bind<IHorsePointsStore>(Bindings.HorsePointsStore).to(HorsePointsStore).inSingletonScope();
	bind<ISecondOptStore>(Bindings.SecondOptStore).to(SecondOptStore).inSingletonScope();
	bind<IGeoBlockStore>(Bindings.GeoBlockStore).to(GeoBlockStore).inSingletonScope();
	bind<ISettingsStore>(Bindings.SettingsStore).to(SettingsStore).inSingletonScope();
	bind<IRankingsStore>(Bindings.RankingsStore).to(RankingsStore).inSingletonScope();
	bind<IRivalTeamStore>(Bindings.RivalTeamStore).to(RivalTeamStore).inSingletonScope();
	bind<IGameBarStore>(Bindings.GameBarStore).to(GameBarStore).inSingletonScope();
});

export const controllers = new ContainerModule((bind: interfaces.Bind) => {
	bind<IFormValidationHelper>(Bindings.FormValidationHelper).to(FormValidationHelper);
	bind<IAuthController>(Bindings.AuthController).to(AuthController);
	bind<IForgotPasswordController>(Bindings.ForgotPasswordController).to(ForgotPasswordController);
	bind<IResetPasswordController>(Bindings.ResetPasswordController).to(ResetPasswordController);
	bind<IMyAccountController>(Bindings.MyAccountController).to(MyAccountController);
	bind<ViewController>(Bindings.SecretGateController).to(SecretGateController);
	bind<IHelpController>(Bindings.HelpController).to(HelpController);
	bind<IHelpListController>(Bindings.HelpListController).to(HelpListController);
	bind<IContactUsController>(Bindings.ContactUsController).to(ContactUsController);
	bind<ILiveScoreController>(Bindings.LiveScoreController).to(LiveScoreController);
	bind<ISessionController>(Bindings.SessionController).to(SessionController);
	bind<IBootstrapController>(Bindings.BootstrapController).to(BootstrapController);
	bind<IMyLeaguesController>(Bindings.MyLeaguesController).to(MyLeaguesController);
	bind<IJoinLeaguesController>(Bindings.JoinLeaguesController).to(JoinLeaguesController);
	bind<IJoinLeaguesPageController>(Bindings.JoinLeaguesPageController).to(
		JoinLeaguesPageController
	);
	bind<ICreateLeagueController>(Bindings.CreateLeagueController).to(CreateLeagueController);
	bind<ILeagueController>(Bindings.LeagueController).to(LeagueController);
	bind<ILeagueInviteController>(Bindings.LeagueInviteController).to(LeagueInviteController);
	bind<ILeagueSettingsController>(Bindings.LeagueSettingsController).to(LeagueSettingsController);
	bind<ILeagueAboutController>(Bindings.LeagueAboutController).to(LeagueAboutController);
	bind<ILeagueInviteFormController>(Bindings.LeagueInviteFormController).to(
		LeagueInviteFormController
	);
	bind<IModalErrorController>(Bindings.ModalErrorController).to(ModalErrorController);
	bind<IModalConfirmController>(Bindings.ModalConfirmController).to(ModalConfirmController);
	bind<ILocalizationController>(Bindings.LocalizationController).to(LocalizationController);
	bind<IModalAuthController>(Bindings.ModalAuthController).to(ModalAuthController);
	bind<IFormRegisterController>(Bindings.FormRegisterController).to(FormRegisterController);
	bind<IFormLoginController>(Bindings.FormLoginController).to(FormLoginController);
	bind<ILandingController>(Bindings.LandingController).to(LandingController);
	bind<IHeaderController>(Bindings.HeaderController).to(HeaderController);
	bind<IMenuController>(Bindings.MenuController).to(MenuController);
	bind<IMenuAccordionController>(Bindings.MenuAccordionController).to(MenuAccordionController);
	bind<IRangeSliderController>(Bindings.RangeSliderController).to(RangeSliderController);
	bind<IRoundSelectorController>(Bindings.RoundSelectorController).to(RoundSelectorController);
	bind<IPlayerPoolController>(Bindings.PlayerPoolController).to(PlayerPoolController);
	bind<ITeamPageController>(Bindings.TeamPageController).to(TeamPageController);
	bind<IPlayerPoolRowController>(Bindings.PlayerPoolRowController).to(PlayerPoolRowController);
	bind<ITeamHorseEmptyController>(Bindings.TeamHorseEmptyController).to(TeamHorseEmptyController);
	bind<ITeamHorseSelectedController>(Bindings.TeamHorseSelectedController).to(
		TeamHorseSelectedController
	);
	bind<IPlayerPoolFiltersController>(Bindings.PlayerPoolFiltersController).to(
		PlayerPoolFiltersController
	);
	bind<IPlayerPoolActionsController>(Bindings.PlayerPoolActionsController).to(
		PlayerPoolActionsController
	);
	bind<ITieBreakerController>(Bindings.TieBreakerController).to(TieBreakerController);
	bind<IModalCaptainPickController>(Bindings.ModalCaptainPickController).to(
		ModalCaptainPickController
	);
	bind<ITeamActionsController>(Bindings.TeamActionsController).to(TeamActionsController);
	bind<IModalDonkeyInsuranceController>(Bindings.ModalDonkeyInsuranceController).to(
		ModalDonkeyInsuranceController
	);
	bind<IBoosterButtonController>(Bindings.BoosterButtonController).to(BoosterButtonController);
	bind<IModalTeamSavedController>(Bindings.ModalTeamSavedController).to(ModalTeamSavedController);
	bind<ITickerController>(Bindings.TickerController).to(TickerController);
	bind<IModalTutorialController>(Bindings.ModalTutorialController).to(ModalTutorialController);
	bind<IModalHorseController>(Bindings.ModalHorseController).to(ModalHorseController);
	bind<IHorseInfoActionsController>(Bindings.HorseInfoActionsController).to(
		HorseInfoActionsController
	);
	bind<IModalTeamChangedController>(Bindings.ModalTeamChangedController).to(
		ModalTeamChangedController
	);
	bind<IModalLeaveNavigateController>(Bindings.ModalLeaveNavigateController).to(
		ModalLeaveNavigateController
	);
	bind<IResetPasswordPageController>(Bindings.ResetPasswordPageController).to(
		ResetPasswordPageController
	);
	bind<IInvitedLeaguesController>(Bindings.InvitedLeaguesController).to(InvitedLeaguesController);
	bind<ILeagueUsersController>(Bindings.LeagueUsersController).to(LeagueUsersController);
	bind<IRacePointsController>(Bindings.RacePointsController).to(RacePointsController);
	bind<ITieBreakerScoreController>(Bindings.TieBreakerScoreController).to(
		TieBreakerScoreController
	);
	bind<IHorseInfoRacesController>(Bindings.HorseInfoRacesController).to(HorseInfoRacesController);
	bind<IHorseInfoSingleRaceController>(Bindings.HorseInfoSingleRaceController).to(
		HorseInfoSingleRaceController
	);
	bind<IModalLeagueInvitedController>(Bindings.ModalLeagueInvitedController).to(
		ModalLeagueInvitedController
	);
	bind<ISectionLeagueSettingsController>(Bindings.SectionLeagueSettingsController).to(
		SectionLeagueSettingsController
	);
	bind<ISectionLeagueLeaveController>(Bindings.SectionLeagueLeaveController).to(
		SectionLeagueLeaveController
	);
	bind<IModalSecondOptController>(Bindings.ModalSecondOptController).to(ModalSecondOptController);
	bind<IGeoBlockController>(Bindings.GeoBlockController).to(GeoBlockController);
	bind<INotificationController>(Bindings.NotificationController).to(NotificationController);
	bind<ILeaderboardFiltersController>(Bindings.LeaderboardFiltersController).to(
		LeaderboardFiltersController
	);
	bind<ILeaderboardController>(Bindings.LeaderboardController).to(LeaderboardController);
	bind<ISharingController>(Bindings.SharingController).to(SharingController);
	bind<IRivalTeamController>(Bindings.RivalTeamController).to(RivalTeamController);
	bind<IRivalHorseController>(Bindings.RivalHorseController).to(RivalHorseController);
	bind<IModalNoRacesController>(Bindings.ModalNoRacesController).to(ModalNoRacesController);
	bind<ILeagueTableController>(Bindings.LeagueTableController).to(LeagueTableController);
	bind<ILeagueLeaderboardFilterController>(Bindings.LeagueLeaderboardFilterController).to(
		LeagueLeaderboardFilterController
	);
	bind<IHorseInfoRaceResultController>(Bindings.HorseInfoRaceResultController).to(
		HorseInfoRaceResultController
	);
	bind<IPlayerPoolActionsCompleteIconController>(
		Bindings.PlayerPoolActionsCompleteIconController
	).to(PlayerPoolActionsCompleteIconController);
	bind<IRaceScheduleController>(Bindings.RaceScheduleController).to(RaceScheduleController);
	bind<IAdvertController>(Bindings.AdvertController).to(AdvertController);
	bind<IRaceScheduleStateController>(Bindings.RaceScheduleStateController).to(
		RaceScheduleStateController
	);
	bind<IRegisterSuccessController>(Bindings.RegisterSuccessController).to(
		RegisterSuccessController
	);
	bind<IModalPersonalizedController>(Bindings.ModalPersonalizedController).to(
		ModalPersonalizedController
	);

	bind<IPlayerPoolBoosterActionsController>(Bindings.PlayerPoolBoosterActionsController).to(
		PlayerPoolBoosterActionsController
	);
	bind<IEventCountdownController>(Bindings.EventCountdownController).to(EventCountdownController);
	bind<IPreRegistrationFormController>(Bindings.PreRegistrationFormController).to(
		PreRegistrationFormController
	);
	bind<IModalTermsController>(Bindings.ModalTermsController).to(ModalTermsController);
});
